
// var moment = require('moment');
// moment.locale('kh');

var ad_zone_01 = false;
var is_loaded_popup = true;
jQuery.fn.extend({
    loading: function () {
      $(this).append('<div class="loading-content"><i class="fa fa-spinner fa-pulse fa-3x fa-fw margin-bottom"></i></div>');
      return;
    }
});

window.view_type = "list";

var symbolMap = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '0': '0'
}, numberMap = {
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  '0': '0'
};

moment.updateLocale('km', {
  preparse: function (string) {
      return string.replace(/[1234567890]/g, function (match) {
          return numberMap[match];
      });
  },
  postformat: function (string) {
      return string.replace(/\d/g, function (match) {
          return symbolMap[match];
      });
  }
});

function update_moment() {

  var options = {
      lastWeek: 'LLLL',
      sameElse: 'LLLL'
  }

  $.each($('.pub-date'),function() {
    var date = $(this).attr('date');
    localtime = moment.utc(date).toDate();
    $(this).text(moment(localtime).calendar(null, options));

  });

  var sdate = $('#sdate').text();
  localtime = moment.utc(sdate).toDate();

  $('#sdate').text(moment(localtime).calendar(null, options));
}

$(document).ready(function() {
  update_moment();

  $('.tab-by-category .nav-tabs li:not(.title, .sponsor) a:not(.switch-view)').on('click', function(){
    $(this).parents('.tab-by-category').find('.nav-tabs li').removeClass('active');
    $(this).parent().addClass('active');
    var url = $(this).attr('href');
    var to = $(this).attr('for');
    var except_ids = $(this).parents('.tab-by-category').attr('rel');
    load_tab_categories(url, to, except_ids);
    return false;
  });

  $('a.switch-view').on('click', function(){
    var type = $(this).attr('id');
    window.view_type = type;
    $('a.switch-view').parent().removeClass('active');
    $(this).parent().addClass('active');
    update_post_view_type();
    return false;
  });

  load_more_posts();
  load_first_tab_category();
  update_post_view_type();
  fixed_menu();  

  $(window).scroll(function(){
    load_more_posts();
    fixed_menu();
    load_first_tab_category();    
    enable_startup_ads();
  })

  $(window).resize(function(){
    update_post_view_type();
    fixed_menu();
    load_first_tab_category();
    display_content_slideshow();
  });

  $(".carousel.slide").carousel({
    swipe: 30 // percent-per-second, default is 50. Pass false to disable swipe
  });
//  $(".carousel.slide").swipe({

//    swipe: function(event, direction, distance, duration, fingerCount, fingerData) {

//      if (direction == 'left') $(this).carousel('next');
//      if (direction == 'right') $(this).carousel('prev');

//    },
//    allowPageScroll:"vertical"

// });

  // #features_post .item .decription .content
  $('#features_post .item a').hover(function() {
    $(this).find('.content').slideDown({queue: false});

    $(this).find('.bg').animate({
      opacity: '1'
    },{ queue: false, duration: 300 } );
    $(this).find('.title').css({
      color: '#e603ea'
    });
  }, function() {
    $(this).find('.content').slideUp({queue: false});

    $(this).find('.bg').animate({
      opacity: '0.5'
    },{ queue: false, duration: 300 } );
    $(this).find('.title').css({
      color: '#ffffff'
    });
  });

  $('#menu #search input').focus(function(){
    if(!window.is_search_show) {
      show_search();
    }
  });

  // $('#menu #search input').focusout(function(){
  //  if(window.is_search_show) {
  //    hide_search();
  //  }
  // });

  $('body').click(function(evt) {
    if($(evt.target).closest('#menu #search').length) {
      return;
    }
    if(window.is_search_show) {
      hide_search();
    }
  });

  $('#menu #search a').click(function() {
    if(!window.is_search_show) {
      show_search();
    } else {
      hide_search();
    }
    return false;
  });

  $('#menu #search form').submit(function(){
    if(!window.is_search_show) {
      show_search();
      $('#menu #search input').focus();
      return false;
    }
  });
  display_content_slideshow();
  // $('#content_slider').on('slid.bs.carousel', function () {
  //   display_content_slideshow();
  // })
  $("#flexiselDemo3").flexisel({
    visibleItems: 3,
    itemsToScroll: 1,
    autoPlay: {
        enable: false,
        interval: 5000,
        pauseOnHover: true
    }
  });

  var scene = document.getElementById('scene');
  if (scene) {
    var parallaxInstance = new Parallax(scene, {
      limitX: 50,
      limitY: 0
    });
  }

  $('.service-close').click(function(){
    $(this).parent().remove();
    return false;
  });
});

function enable_startup_ads(){
  
  if ($(window).scrollTop() > 5){
    if (is_loaded_popup) {
      var mobile = ($( window ).width() <= 768) ? true:false;
      if (mobile) {
        $('#ad_zone_09').show();
        var link = "";
        var img = "";
        $('#ad_zone_09 ins a').each(function(){
          link = $(this).attr('href');
        });
        $('#ad_zone_09 ins a img').each(function(){
          img = $(this).attr('src');
        });
      } else {
        $('#ad_zone_08').show();
        var link = "";
        var img = "";
        $('#ad_zone_08 ins a').each(function(){
          link = $(this).attr('href');
        });
        $('#ad_zone_08 ins a img').each(function(){
          img = $(this).attr('src');
        });
      }
      
      sb_ads_startup(link, img);
      is_loaded_popup = false;
    }
  } else {
    $('.start_up_ads').hide();
  }
  
}
function display_content_slideshow()
{
  $(".content_slider").each(function() {
    var slide = $(this);
    var items = slide.find('.item');
    var a = false;
    items.each( function( index, element ){
      if(!$(this).hasClass("active")) {
        $(this).addClass("active");
        a = true;
      }
      var caption_h = 0;
      if($(this).find('.carousel-caption').length) {
        caption_h = $(this).find('.carousel-caption').height() + 20;
      }
      height = (slide.height() - caption_h);
      $(this).find('.img').height(height);
      if(a) {
        $(this).removeClass("active");
        a = false;
      }
    });
  });
}

window.is_search_show = false;
function show_search() {
  window.is_search_show = true;
  var width = $(document).innerWidth();
  if(width <= 767) {
    $('#menu #search input').focus();
    $('#menu #search form button.left_submit').fadeIn();
    $('#menu #search input').css({'padding':'0 15px 0 38px'});
    $('#menu #search a').html('<i class="fa fa-times"></i>');
    $('#menu #search input').animate({
        width: 275
    },{ queue: false, duration: 300 } );
  } else {
    $('#menu #search input').css({'padding':'0 30px 0 15px'});
    $('#menu #search input').animate({
        width: 300
    },{ queue: false, duration: 300 } );
  }

}

function hide_search() {
  window.is_search_show = false;
  var width = $(document).innerWidth();
  var toW = 0;
  if(width <= 767) {
    $('#menu #search form button.left_submit').hide();
    $('#menu #search a').html('<i class="fa fa-search"></i>');
    $('#menu #search form button').css({'color':'#ffffff'});
  }
  $('#menu #search input').css({'padding':'0'});
  $('#menu #search input').animate({
    width: toW
  },{ queue: false, duration: 300 } );
}

$(document).on("click", ".tab-by-category .btn_next_prev[rel=ajax]",function(e){
  var url = $(this).attr('href');
  var to = $(this).parents('.tab-by-category').find('.ajax-content').attr('id');
  var except_ids = $(this).parents('.tab-by-category').attr('rel');
  load_tab_categories(url, to, except_ids);
  return false;
});


window.loaded_categies_arr = [];
function load_first_tab_category() {
  var elements = $('div.tab-by-category');
  if(typeof elements.offset() != "undefined") {
    var scroll = $(this).scrollTop() + $(window).innerHeight() + 150;
    elements.each(function(){
      var tab_id = $(this).attr('id');
      if((window.loaded_categies_arr.indexOf(tab_id) == -1) && scroll >= $(this).offset().top) {
        window.loaded_categies_arr.push(tab_id);
        var tab = $(this).find('.nav-tabs li.active a[rel=ajax]');
        if(typeof tab.offset() != "undefined") {
          var url = tab.attr('href');
          var to = tab.attr('for');
          var except_ids = $(this).attr('rel');
          load_tab_categories(url, to, except_ids);
        }
      }
    });
  }
}

function fixed_menu() {
  var before_menu = $('div#before_menu');
  var menu = $('nav#menu');
  var width = $(document).innerWidth();
  if(width <= 767) {
    menu.removeClass('navbar-static-top').addClass('navbar-fixed-top');
    $('.mean-content').css({'margin-top': menu.height()+'px'});
    return;
  }

  if(typeof before_menu.offset() != "undefined") {
    if(($(this).scrollTop() >= before_menu.offset().top)) {
      menu.removeClass('navbar-static-top').addClass('navbar-fixed-top');
      $('.mean-content').css({'margin-top': (menu.height()+15)+'px'});      
    } else {      
      menu.removeClass('navbar-fixed-top').addClass('navbar-static-top');
      $('.mean-content').css({'margin-top': '15px'});
      ($('.mean-content').hasClass('special')) ? $('.mean-content').css({'margin-top': '0'}): '';
    }
  }
}

function update_post_view_type()
{
  var type = window.view_type;
  var width = $(document).innerWidth();
  if(width <= 767 || type == "grid") {
    $('#posts_list').removeClass('list').addClass('grid');
    $('#posts_list .list-items').addClass('row');
    $('#posts_list .list-items .list-item').removeClass('row').addClass('col-md-4').addClass('col-xs-6');
    $('#posts_list .list-items .list-item .tumbnail').removeClass('col-md-4');
    $('#posts_list .list-items .list-item .content').removeClass('col-md-8');
  } else {
    $('#posts_list').removeClass('grid').addClass('list');
    $('#posts_list .list-items').removeClass('row');
    $('#posts_list .list-items .list-item').addClass('row').removeClass('col-md-4').removeClass('col-xs-6');
    $('#posts_list .list-items .list-item .tumbnail').addClass('col-md-4');
    $('#posts_list .list-items .list-item .content').addClass('col-md-8');
  }
}


function load_tab_categories(url, to, except_ids)
{
  $(".ajax-content#"+to).loading();
  $.ajax({
    url: url,
    // data: {
    //   'except_ids':except_ids
    // },
    success: function(result){
     $(".ajax-content#"+to).html(result);
    }
  });
}

function do_ajax_action(url, callback)
{
  $.ajax({
    url: url,
    success: function(result){
     callback(result);
    }
  });
}

window.is_loading = false;
window.current_page = 1;

function load_more_posts()
{
  var loading = $('#loading_more_post');
  if(typeof loading.offset() != "undefined") {
    if(($(this).scrollTop() + $(window).height() + 200) >= loading.offset().top && !window.is_loading){
//      piwikTracker.trackGoal(10);
        window.is_loading = true;
        window.current_page += 1;
        var page = window.current_page + 1;
        var rel = loading.attr('rel');
        var url = DOMAIN + rel + "/" + window.current_page;
        loading.find('i').show();
        
        $.ajax({
          url: url,
          success: function(result){
            loading.find('i').hide();
            if(result != "") {

              var category_bottom_ads = "";

              $('#category_bottom_ads').find('.ads_items').each(function(e) {
                var zone = $(this);
                var zone_id = zone.attr('zone');
                if(typeof zone_id !== "undefined") {
                  var random_number = Math.floor(Math.random()*99999999999);
                  var mobile = ($( window ).width() <= 768) ? true:false;
                  var width  = 728;
                  var height = 90;
                  if (mobile) {
                    width  = 300;
                    height = 250;
                  }
                  var ads = "<div class='ads_items' style='margin-bottom:20px;'><iframe id='a5dd2a7c' name='a5dd2a7c' src='https://ads.sabay.com/openx/www/delivery/afr.php?zoneid="+zone_id+"&amp;cb="+random_number+"' frameborder='0' scrolling='no' width='"+width+"' height='"+height+"'><a href='https://ads.sabay.com/openx/www/delivery/ck.php?n=ae6639d1&amp;cb="+random_number+"' target='_blank'><img class='img-responsive' src='https://ads.sabay.com/openx/www/delivery/avw.php?zoneid="+zone_id+"&amp;cb="+random_number+"&amp;n=ae6639d1' border='0' alt='' /></a></iframe></div>";

                  category_bottom_ads += ads;
                }
              });

              $('#posts_list .list-items').append(result).append(category_bottom_ads);

              update_moment();
                update_post_view_type();
                window.is_loading = false;
            }
          }
        });
    }
  }
}

$(document).ready(function() {
   $("a[href^=http]").each(function(){
      var excluded = [
         // format for whitelist: 'google.com', 'apple.com', 'myawesomeblog.com'
         // add your excluded domains here
         ];
      for(i=0; i<excluded.length; i++) {
         if(this.href.indexOf(excluded[i]) != -1) {
            return true;
         }
      }
      if(this.href.indexOf(location.hostname) == -1) {
           $(this).click(function() { return true; });
           $(this).attr({
               target: "_blank",
               title: "Opens in a new window"
           });
           $(this).click();
      }
   });
   //click on submemu in mobile view
   $(".dropdown-menu-mobile li").click(function(e){
    e.preventDefault();
    window.location.href = $(this).attr('href');
   });
});


